import React from 'react';
import clsx from 'clsx';

const Button = ({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  return (
    <button className={clsx(['btn btn-primary btn-sm', className])} {...rest}>
      {children}
    </button>
  );
};

export default Button;
