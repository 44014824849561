import React, { FC } from 'react';
import Header from '../Header';

const Layout: FC = ({ children }) => {
  return (
    <div className="h-full sm:h-screen md:overflow-y-hidden">
      <Header />
      <main className="mx-auto flex h-full w-full flex-col items-center pb-4">
        {children}
      </main>
    </div>
  );
};

export default Layout;
