import { useSession } from 'next-auth/react';
import { signIn, signOut } from 'next-auth/react';
import Link from 'next/link';
import Button from '../Button';

const Header = () => {
  const { status } = useSession();
  return (
    <div className="navbar justify-end">
      {status === 'loading' ? null : status === 'authenticated' ? (
        <div className="flex justify-end gap-2">
          <Link href="/boards" passHref>
            <Button>Boards</Button>
          </Link>
          <Button
            onClick={() =>
              signOut({
                callbackUrl: '/',
              })
            }
          >
            Logout
          </Button>
        </div>
      ) : (
        <Button onClick={() => signIn()}>Login</Button>
      )}
    </div>
  );
};
export default Header;
