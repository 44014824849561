import type { GetServerSideProps, NextPage } from 'next';
import { useSession, getSession } from 'next-auth/react';
import Layout from '../components/Layout';

const Home: NextPage = () => {
  const { status } = useSession();
  return (
    <Layout>
      <div>{status === 'loading' && 'Loading...'}</div>
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getSession(context);

  if (session) {
    return {
      redirect: {
        destination: '/boards',
        statusCode: 307,
      },
    };
  }

  return {
    props: {},
  };
};

export default Home;
